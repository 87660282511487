import React, { useState } from "react";
import "./MapWrapper.css";
import "../selectOption/SelectOption.css";
import { getFloor, getFloorObj } from "../../../utils/helper";
import { Footer } from "../../elements/footer";

import first_floor from "../../../assets/images/map/first_floor.png";
import second_floor from "../../../assets/images/map/second_floor.png";
import third_floor from "../../../assets/images/map/third_floor.png";
import fourth_floor from "../../../assets/images/map/fourth_floor.png";
import ground_floor from "../../../assets/images/map/ground_floor.png";
import lower_basement from "../../../assets/images/map/lower_basement.png";
import upper_basement from "../../../assets/images/map/upper_basement.png";

import first_floor_with_marker from "../../../assets/images/map/first_floor_with_marker.png";
import second_floor_with_marker from "../../../assets/images/map/second_floor_with_marker.png";
import third_floor_with_marker from "../../../assets/images/map/third_floor_with_marker.png";
import fourth_floor_with_marker from "../../../assets/images/map/fourth_floor_with_marker.png";
import ground_floor_with_marker from "../../../assets/images/map/ground_floor_with_marker.png";
import lower_basement_with_marker from "../../../assets/images/map/lower_basement_with_marker.png";
import upper_basement_with_marker from "../../../assets/images/map/upper_basement_with_marker.png";

import first_floor_nepali from "../../../assets/images/map/first_floor_nepali.png";
import second_floor_nepali from "../../../assets/images/map/second_floor_nepali.png";
import third_floor_nepali from "../../../assets/images/map/third_floor_nepali.png";
import fourth_floor_nepali from "../../../assets/images/map/fourth_floor_nepali.png";
import ground_floor_nepali from "../../../assets/images/map/ground_floor_nepali.png";
import lower_basement_nepali from "../../../assets/images/map/lower_basement_nepali.png";
import upper_basement_nepali from "../../../assets/images/map/upper_basement_nepali.png";

import first_floor_nepali_with_marker from "../../../assets/images/map/first_floor_nepali_with_marker.png";
import second_floor_nepali_with_marker from "../../../assets/images/map/second_floor_nepali_with_marker.png";
import third_floor_nepali_with_marker from "../../../assets/images/map/third_floor_nepali_with_marker.png";
import fourth_floor_nepali_with_marker from "../../../assets/images/map/fourth_floor_nepali_with_marker.png";
import ground_floor_nepali_with_marker from "../../../assets/images/map/ground_floor_nepali_with_marker.png";
import lower_basement_nepali_with_marker from "../../../assets/images/map/lower_basement_nepali_with_marker.png";
import upper_basement_nepali_with_marker from "../../../assets/images/map/upper_basement_nepali_with_marker.png";

import uk_flag from "../../../assets/images/english.svg";
import nepal_flag from "../../../assets/images/nepali.svg";
import kioskLogo from "../../../assets/images/kiosk_logo.png";
import { ImageElem } from "../../elements/imageElem";

export const MapWrapper = ({ floor, setFloor }) => {
  const [language, setLanguage] = useState("NEPALI");
  const floorList = [
    {
      id: "le",
      language: "ENGLISH",
      name: "Lower Basement",
      image: window.location?.search.includes("LOWER")
        ? lower_basement_with_marker
        : lower_basement,
      number: "l",
    },
    {
      id: "ln",
      language: "NEPALI",
      name: "तल्लो तहखाने तल्ला",
      image: window.location?.search.includes("LOWER")
        ? lower_basement_nepali_with_marker
        : lower_basement_nepali,
      number: "l",
    },
    {
      id: "ue",
      language: "ENGLISH",
      name: "Upper Basement",
      image: window.location?.search.includes("UPPER")
        ? upper_basement_with_marker
        : upper_basement,
      number: "u",
    },
    {
      id: "un",
      language: "NEPALI",
      name: "माथिल्लो तहखाने तल्ला",
      image: window.location?.search.includes("UPPER")
        ? upper_basement_nepali_with_marker
        : upper_basement_nepali,
      number: "u",
    },
    {
      id: "ge",
      language: "ENGLISH",
      name: "Ground Floor",
      image: window.location?.search.includes("GROUND")
        ? ground_floor_with_marker
        : ground_floor,
      number: "g",
    },
    {
      id: "gn",
      language: "NEPALI",
      name: "भुई तल्ला",
      image: window.location?.search.includes("GROUND")
        ? ground_floor_nepali_with_marker
        : ground_floor_nepali,
      number: "g",
    },
    {
      id: "1e",
      language: "ENGLISH",
      name: "First Floor",
      image: window.location?.search.includes("FIRST")
        ? first_floor_with_marker
        : first_floor,
      number: "1",
    },
    {
      id: "1n",
      language: "NEPALI",
      name: "पहिलो तल्ला",
      image: window.location?.search.includes("FIRST")
        ? first_floor_nepali_with_marker
        : first_floor_nepali,
      number: "1",
    },
    {
      id: "2e",
      language: "ENGLISH",
      name: "Second Floor",
      image: window.location?.search.includes("SECOND")
        ? second_floor_with_marker
        : second_floor,
      number: "2",
    },
    {
      id: "2n",
      language: "NEPALI",
      name: "दोस्रो तल्ला",
      image: window.location?.search.includes("SECOND")
        ? second_floor_nepali_with_marker
        : second_floor_nepali,
      number: "2",
    },
    {
      id: "3e",
      language: "ENGLISH",
      name: "Third Floor",
      image: window.location?.search.includes("THIRD")
        ? third_floor_with_marker
        : third_floor,
      number: "3",
    },
    {
      id: "3n",
      language: "NEPALI",
      name: "तेस्रो तल्ला",
      image: window.location?.search.includes("THIRD")
        ? third_floor_nepali_with_marker
        : third_floor_nepali,
      number: "3",
    },
    {
      id: "4e",
      language: "ENGLISH",
      name: "Fourth Floor",
      image: window.location?.search.includes("FOURTH")
        ? fourth_floor_with_marker
        : fourth_floor,
      number: "4",
    },
    {
      id: "4n",
      language: "NEPALI",
      name: "चौथो तल्ला",
      image: window.location?.search.includes("FOURTH")
        ? fourth_floor_nepali_with_marker
        : fourth_floor_nepali,
      number: "4",
    },
  ];
  const floorNumbers = ["l", "u", "g", "1", "2", "3", "4"];
  
  return (
    <div
      className="main-wrapper d-flex"
      style={{
        background: "#fff",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        className="flex-centered"
        style={{
          position: "relative",
          width: "100%",
          flex: 1,
          height: "calc(100vh - 60px)",
        }}
      >
        <div
          style={{
            border: "2px solid #333",
            height: "calc(100vh - 60px)",
            flex: 1,
          }}
        >
          <ImageElem floor={floor} language={language} floorList={floorList} />
        </div>

        <div
          className="right-container"
          style={{ background: "#faebd757", height: "100%", width: "420px" }}
        >
          <div className="logo-section">
            <img src={kioskLogo} style={{ width: "80px", height: "80px" }} />
            <span style={{ fontSize: "28px", fontWeight: "500" }}>
              {language === "ENGLISH"
                ? "Bhaktapur District Court"
                : "भक्तपुर जिल्ला अदालत"}
            </span>

            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {language === "ENGLISH" ? "Bhaktapur, Nepal" : "भक्तपुर, नेपाल"}
            </span>
          </div>

          <div className="language-options d-flex">
            <div
              className="language-option-wrapper"
              style={{
                background: language === "ENGLISH" ? "#004680" : "#fff",
                border:
                  language === "ENGLISH" ? "1px solid 004680" : "#1px solid",
                color: language === "ENGLISH" ? "#fff" : "",
              }}
              onClick={() => setLanguage("ENGLISH")}
            >
              <img src={uk_flag} alt="english" className="language-flag-img" />
              English
            </div>
            <div
              className="language-option-wrapper"
              style={{
                background: language === "NEPALI" ? "#004680" : "#fff",
                border:
                  language === "NEPALI" ? "1px solid 004680" : "1px solid",
                color: language === "NEPALI" ? "#fff" : "",
              }}
              onClick={() => setLanguage("NEPALI")}
            >
              <img
                style={{ width: "17px", height: "17px" }}
                src={nepal_flag}
                alt="nepal"
                className="language-flag-img"
              />
              नेपाली
            </div>
          </div>

          <div
            className="floor-section"
            style={{
              border: "4px solid #004680",
              borderRadius: "18px",
            }}
          >
            <div
              className="flex-centered"
              style={{
                color: "#fff",
                fontSize: "24px",
                backgroundColor: "#004680",
                borderRadius: "12px 12px 0 0",
                padding: "12px",
              }}
            >
              {language === "ENGLISH" ? "Select the floor" : "तल्ला छान्नुहोस्"}
            </div>
            <div
              className="flex-centered"
              style={{
                background: "",
                overflow: "hidden",
                // padding: "6px",
                flexDirection: "column",
              }}
            >
              {floorNumbers &&
                floorNumbers.map((single) => (
                  <div
                    className="flex-centered"
                    style={
                      floor === single
                        ? {
                            backgroundColor: "#00468080",
                            color: "#fff",
                            width: "13rem",
                            height: "40px",
                            fontSize: "22px",
                            fontWeight: 600,
                            padding: "12px",
                            width: "300px",
                          }
                        : {
                            color: "#004680",
                            fontSize: "22px",
                            padding: "12px",
                            width: "13rem",
                            height: "40px",
                            width: "300px",
                          }
                    }
                    onClick={() => {
                      setFloor(single);
                      // interval = setInterval(() => intervalFunc, 5000);
                      
                    }}
                  >
                    {getFloorObj(floorList, single, language) &&
                      getFloorObj(floorList, single, language).name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <Footer language={language}/>
    </div>
  );
};
