import React from "react";
import "./LandingPage.css";
import { Logo } from "../../elements/logo";
import { PAGE } from "../../../utils/constants";
import { getCurrentMonth, getCurrentTime } from "../../../utils/helper";

export const LandingPage = ({ setPage }) => {
  return (
    <div className="main-wrapper landing-wrapper" >
      <div className="d-flex">
        <div>
          <Logo onClick={() => setPage(PAGE.LANDING)} />
        </div>
        <div className="landing-text">
          <div>Looks like</div>
          <div>you got Lost</div>
        </div>
      </div>

      <div className="touch-btn-wrapper">
        <div className="outline" onClick={() => setPage(PAGE.SELECT_OPTION)}>
          <div className="touch-btn">TOUCH SCREEN TO START</div>
        </div>
      </div>

      <div className="date-wrapper flex-centered">
        <div className="date-content">
          <div>{new Date().getDate()}</div>
          <div>{getCurrentMonth()}</div>
        </div>
        <div className="time-content">{getCurrentTime()}</div>
      </div>
    </div>
  );
};
