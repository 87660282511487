import { useEffect, useState } from "react";
import "./App.css";
import { PAGE } from "./utils/constants";
import { LandingPage } from "./components/layouts/landingPage/LandingPage";
import { MapWrapper } from "./components/layouts/mapWrapper/MapWrapper";
import { getFloor } from "./utils/helper";

function App() {
  const [page, setPage] = useState(PAGE.MAP_WRAPPER);
  const [flag, setFlag] = useState("ENGLISH");
  const [floor, setFloor] = useState(getFloor());
  const [floorChangedTime, setFloorChangedTime] = useState(0);

  // useEffect(() => {
  //   console.log("#### Floor changed ####");
  //   if (floor !== getFloor()) {
  //     setFloorChangedTime(new Date().getTime());
  //   }
  // }, [floor]);

  const renderPage = () => {
    switch (page) {
      case PAGE.LANDING:
        return <LandingPage setPage={setPage} />;
      case PAGE.SELECT_OPTION:
        return <></>;
      case PAGE.MAP_WRAPPER:
        return (
          <MapWrapper
            language={flag}
            floor={floor}
            setPage={setPage}
            setFloor={setFloor}
          />
        );
      default:
        return <></>;
    }
  };
  return <>{renderPage()}</>;
}

export default App;
