export const getFloorObj = (floorList, id, language) => {
  const filteredObj =
    floorList &&
    floorList.filter(
      (single) => single.number?.includes(id) && single.language === language
    );
  return filteredObj && filteredObj[0];
};

export const isSelectedFloor = (floorList, id) => {
  const filteredObj =
    floorList && floorList.filter((single) => single.number?.includes(id));
  if (filteredObj && filteredObj[0]) {
    return true;
  }
  return false;
};

export const getCurrentMonth = () => {
  // Create a Date object for the current date and time
  const currentDate = new Date();

  // Define arrays for month names and AM/PM
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format the date and time
  return months[currentDate.getMonth()];
};

export const getCurrentTime = () => {
  // Create a Date object for the current date and time
  const currentDate = new Date();

  const ampm = currentDate.getHours() >= 12 ? "PM" : "AM";

  // Get the hour, and minute components
  const hour = currentDate.getHours() % 12 || 12; // Convert to 12-hour format
  const minute = currentDate.getMinutes();

  // Format the date and time
  return `${hour}:${minute < 10 ? "0" : ""}${minute} ${ampm}`;
};

export const getFloor = () => {
  return window.location?.search.includes("LOWER")
    ? "l"
    : window.location?.search.includes("UPPER")
    ? "u"
    : window.location?.search.includes("GROUND")
    ? "g"
    : window.location?.search.includes("FOURTH")
    ? "4"
    : window.location?.search.includes("SECOND")
    ? "2"
    : window.location?.search.includes("THIRD")
    ? "3"
    : "1";
};
