import React, { memo, useEffect, useRef, useState } from "react";
import "../../layouts/mapWrapper/MapWrapper.css";
import { MapInteractionCSS } from "react-map-interaction";
import { getFloorObj } from "../../../utils/helper";

export const ImageElem = memo(({ floor, language, floorList }) => {
  const [mapValue, setMapValue] = useState({
    scale: 1,
    translation: { x: 4, y: 0 },
  });
  const imgRef = useRef();

  const [changedTime, setChangedTime] = useState(0);

  useEffect(() => {
    setMapValue({
      scale: 1,
      translation: { x: 4, y: 0 },
    });
  }, [floor]);

  return (
    <MapInteractionCSS
      minScale={1}
      maxScale={10}
      value={mapValue}
      onChange={(value) => {
        setChangedTime(new Date());
        setMapValue(value);
        setInterval(() => {
          // if((new Date() - changedTime) > 10){
          setMapValue({
            scale: 1,
            translation: { x: 4, y: 0 },
          });
          // }
        }, [120000]);
      }}
    >
      <div style={{ width: "calc(100vw - 60%)" }}>
        <img
          ref={imgRef}
          alt="img"
          className="map-image"
          src={
            // first_floor
            getFloorObj(floorList, floor, language) &&
            getFloorObj(floorList, floor, language).image
          }
        />
      </div>
    </MapInteractionCSS>
  );
});
