import React from "react";
import "./index.css";

export const Footer = ({language}) => {
  return (
    <div className="footer-wrapper">
      {language === "ENGLISH" ? (
        <marquee width="100%" direction="left">
          Welcome to BHAKTAPUR DISTRICT COURT. &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Welcome to BHAKTAPUR
          DISTRICT COURT. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; Welcome to BHAKTAPUR DISTRICT COURT. &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          Welcome to BHAKTAPUR DISTRICT COURT. &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Welcome to BHAKTAPUR
          DISTRICT COURT. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; Welcome to BHAKTAPUR DISTRICT COURT. &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </marquee>
      ) : (
        <marquee width="100%" direction="left">
          भक्तपुर जिल्ला अदालतमा यहाँहरुलाई स्वागत छ। &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; भक्तपुर जिल्ला अदालतमा यहाँहरुलाई स्वागत छ। &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; भक्तपुर जिल्ला अदालतमा यहाँहरुलाई स्वागत छ।
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          भक्तपुर जिल्ला अदालतमा यहाँहरुलाई स्वागत छ। &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; भक्तपुर जिल्ला अदालतमा यहाँहरुलाई स्वागत छ। &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; भक्तपुर जिल्ला अदालतमा यहाँहरुलाई स्वागत छ।
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </marquee>
      )}
    </div>
  );
};
