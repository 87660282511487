import React from "react";
import "./index.css";
import logo from "../../../assets/images/kiosk_logo.png";

export const Logo = ({ onClick }) => {
  return (
    <div className="logo-wrapper" onClick={onClick}>
      <img src={logo} style={{ width: 105, height: 105 }} />
    </div>
  );
};
